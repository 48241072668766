import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import ClientNav from "./components/ClientNav.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import PaginationComponent from "../../../components/PaginationComponent.js";
import CarsTable from "../../Cars/List/components/CarsTable.js";
import {fetchClientCars, resetStateData as resetCarListStateData } from "../../../slices/carListSlice.js";

const ClientCarsPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const {data: cars, loading, pagination} = useSelector((state) => state.carList);
    const [searchParams, setSearchParams] = useState({page: 1, search: '', filters: {}})
    const {data: client} = useSelector((state) => state.client);

    useEffect(() => {
        return () => {
            dispatch(resetCarListStateData());
        }
    }, []);

    useEffect(() => {
        dispatch(fetchClientCars({...searchParams, id: id}));
    }, [searchParams]);

    const handlePageChange = (page) => {
        setSearchParams({
            page: page,
            filters: searchParams.filters,
            search: searchParams.search
        })
    };

    const showTable = () => {
        return !loading && client.id;
    }

    return (
        <SingleViewLayout
            title={`Клиент № ${id} - ${client.name}`}
            navComponent={ClientNav}
            navProps={{id: id, activeKey: 'cars'}}
            showPage={showTable()}
        >
            <CarsTable data={cars} showTable={showTable()} />
            <PaginationComponent
                onPageChange={handlePageChange}
                pagination={pagination}
            />

        </SingleViewLayout>
    )
};

export default ClientCarsPage;