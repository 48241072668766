import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import LoadingComponent from "../LoadingComponent.js";
import PaginationComponent from "../PaginationComponent.js";
import apiClient from "../../axios.js";
import StandardTable from "../StandardTable.js";
import {formatDateTimeToBeautifulString} from "../../utils/dateFunctions.js";

const LogsPageLayout = (
    {
        id,
        title,
        items,
        navComponent : NavComponent,
        navProps,
        showPage
    }) => {
    const endPoint = `/${items}/${id}/logs`;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: null,
        totalItems: null
    })

    const handlePageChange = (page) => {
        setPagination((prev) => {
            const pagination = {...prev};
            pagination.currentPage = page;
            return pagination;
        })
    };

    const fetchData = async () => {
        setLoading(true);
        const response = await apiClient.get(`${endPoint}?page=${pagination.currentPage}`);
        setData(response.data.data);
        setPagination({
            currentPage: response.data.meta.currentPage,
            lastPage: response.data.meta.lastPage,
            totalItems: response.data.meta.total
        });
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [pagination.currentPage]);

    return (
        <div>
            <h2 className={!showPage ? 'blurry-text' : ''}>{title}</h2>
            {NavComponent && <NavComponent {...navProps} />}
            <Card>
                {showPage && !loading ? (
                    <>
                        <Card.Body>
                            <StandardTable headers={['Промяна', 'Потребител', 'Дата']}>
                                {data.map((log, index) => (
                                    <tr key={`${log.id}-${index}`}>
                                        <td>
                                        {log.change.map((changeItem, subIndex) => (
                                            <div key={`${log.id}-${index}-${subIndex}`}>
                                                <span><strong>{changeItem.field}</strong></span><br/>
                                                <span>Стара стойност:<br/> {changeItem.old}</span><br/>
                                                <span>Нова стойност:<br/> {changeItem.new}</span><br/>
                                            </div>
                                        ))}
                                        </td>
                                        <td>{log.userName}</td>
                                        <td>{formatDateTimeToBeautifulString(log.createdAt)}</td>
                                    </tr>
                                ))}
                            </StandardTable>
                            <PaginationComponent
                                onPageChange={handlePageChange}
                                pagination={pagination}
                            />
                        </Card.Body>
                    </>
                ) : (
                    <LoadingComponent/>
                )}
            </Card>
        </div>
    )
}

export default LogsPageLayout;