import {useParams} from "react-router-dom";
import { useSelector} from "react-redux";
import {useEffect, useState} from "react";
import LogsPageLayout from "../../../components/Layouts/LogsPageLayout.js";
import OrderNav from "./components/OrderNav.js";

const OrderLogsPage = () => {
    const { id } = useParams();
    const { data: order, loading } = useSelector((state) => state.order);

    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Поръчка № ${id}`);
    }, [order.id]);

    const showPage = () => {
        return !loading;
    }

    return (
        <>
            <LogsPageLayout
                id={id}
                title={title}
                items={'orders_order_lines'}
                navComponent={OrderNav}
                navProps={{id: id, activeKey: 'logs'}}
                showPage={showPage()}
            />
        </>
    )
}

export default OrderLogsPage;