import {useParams} from "react-router-dom";
import { useSelector} from "react-redux";
import {useEffect, useState} from "react";
import LogsPageLayout from "../../../components/Layouts/LogsPageLayout.js";
import ClientNav from "./components/ClientNav.js";

const ClientLogsPage = () => {
    const { id } = useParams();
    const { data: client, loading } = useSelector((state) => state.client);

    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Клиент № ${id} - ${client.name}`);
    }, [client.id]);

    const showPage = () => {
        return !loading;
    }

    return (
        <>
            <LogsPageLayout
                id={id}
                title={title}
                items={'clients'}
                navComponent={ClientNav}
                navProps={{id: id, activeKey: 'logs'}}
                showPage={showPage()}
            />
        </>
    )
}

export default ClientLogsPage;