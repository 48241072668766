import {useParams} from "react-router-dom";
import { useSelector} from "react-redux";
import {useEffect, useState} from "react";
import LogsPageLayout from "../../../components/Layouts/LogsPageLayout.js";
import ArticleNav from "./components/ArticleNav.js";

const ArticleLogsPage = () => {
    const { id } = useParams();
    const { data: article, loading } = useSelector((state) => state.article);

    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Артикул № ${id} - ${article.name}`);
    }, [article.id])

    const showPage = () => {
        return !loading;
    }

    return (
        <>
            <LogsPageLayout
                id={id}
                title={title}
                items={'articles'}
                navComponent={ArticleNav}
                navProps={{id: id, activeKey: 'logs'}}
                showPage={showPage()}
            />
        </>
    )
}

export default ArticleLogsPage;