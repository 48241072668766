import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams} from "react-router-dom";
import {
    fetchCarOrders,
    resetStateData as resetOrderListStateData,
    setCurrentPage
} from "../../../slices/orderListSlice.js";
import OrdersTable from "../../Orders/List/components/OrdersTable.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import PaginationComponent from "../../../components/PaginationComponent.js";
import CarNav from "./components/CarNav.js";

const CarOrdersPage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { data: orders, loading, pagination } = useSelector((state) => state.orderList);
    const { data: car } = useSelector((state) => state.car);

    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(`Кола № ${id} - ${car.registrationNumber}` );
    }, [car.id]);

    useEffect(() => {
        return () => {
            dispatch(resetOrderListStateData());
        }
    }, []);

    const handlePageChange = (page) => {
        dispatch(setCurrentPage(page));
    };

    const fetchOrders = () => {
        dispatch(fetchCarOrders({page: pagination.currentPage, id: id}));
    }

    useEffect(() => {
        fetchOrders();
    }, [dispatch, pagination.currentPage, id]);

    const showTable = () => {
        return !loading && car.id;
    }

    return (
        <SingleViewLayout
            title={title}
            navComponent={CarNav}
            navProps={{id: id, activeKey: 'orders'}}
            showPage={showTable()}
        >
            <OrdersTable data={orders} showTable={() => !loading} onItemSave={fetchOrders}/>
            <PaginationComponent onPageChange={handlePageChange} pagination={pagination}/>

        </SingleViewLayout>
    );
};

export default CarOrdersPage;
