import {Table} from "react-bootstrap";
import {formatDateTimeToBeautifulString} from "../../../../utils/dateFunctions.js";
import React from "react";

const OrderCommentsTable = ({
    data
}) => {

    return (
        <Table bordered hover striped>
            <thead>
            <tr>
                <th>Действие</th>
                <th>Пояснение</th>
                <th>Коментар</th>
                <th>Потребител</th>
                <th>Дата</th>
            </tr>
            </thead>
            <tbody>
            {data.map((comment, index) => (
                <tr key={`order-comment-key-${index}`}>
                    <td>{comment.action}</td>
                    <td>{comment.actionDetail}</td>
                    <td>{comment.comment}</td>
                    <td>{comment.user.name}</td>
                    <td>{formatDateTimeToBeautifulString(comment.createdAt)}</td>
                </tr>
            ))}
            </tbody>
        </Table>
    )
}

export default OrderCommentsTable;