import {Routes, Route} from "react-router-dom";
import ClientListPage from "../pages/Clients/List/ClientListPage.js";
import ClientEditPage from "../pages/Clients/Single/ClientEditPage.js";
import {hasPermission} from "../utils/permissions.js";
import ClientViewPage from "../pages/Clients/Single/ClientViewPage.js";
import ClientOrdersPage from "../pages/Clients/Single/ClientOrdersPage.js";
import ClientCarsPage from "../pages/Clients/Single/ClientCarsPage.js";
import SingleClientWrapper from "../pages/Clients/SingleClientWrapper.js";
import ClientLogsPage from "../pages/Clients/Single/ClientLogsPage.js";
import ClientFilesPage from "../pages/Clients/Single/ClientFilesPage.js";

const ClientRoutes = (
    {
        user
    }) => (
    <Routes>
        <Route index element={<ClientListPage />} />
        <Route
            path={':id'}
            element={<SingleClientWrapper/>}
        >
            <Route index element={<ClientViewPage />} />
            <Route path={'edit'} element={hasPermission(user, 'update-clients') ? <ClientEditPage /> : <ClientViewPage />}  />
            <Route path={'orders'} element={<ClientOrdersPage />} />
            <Route path={'cars'} element={<ClientCarsPage /> } />
            <Route path={'files'} element={hasPermission(user, 'update-clients') ? <ClientFilesPage /> : <ClientViewPage />}  />
            <Route path={'logs'} element={hasPermission(user, 'get-logs') ? <ClientLogsPage /> : <ClientViewPage />}  />
        </Route>
    </Routes>
);

export default ClientRoutes;