import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams,  useNavigate} from "react-router-dom";
import {changeAttribute, fetchUser, saveUser, resetStateData} from "../../../slices/userSlice.js";
import {loadInitialData, processIdInformation, processSubmit} from "../../../utils/functions.js";
import {getUserSchema} from "../../../utils/schemas.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import UserForm from "./components/UserForm.js";
import {fetchRoles} from "../../../slices/roleListSlice.js";
import {setSuccessMessage} from "../../../slices/informationMessagesSlice.js";

const UserEditPage = () => {
    const {id} = useParams();
    const {idIsInteger, isValidId} = processIdInformation(id);

    const dispatch = useDispatch();
    const {data: user, loading, errors: stateErrors, status} = useSelector((state) => state.user);
    const {data: roles, loading: rolesLoading} = useSelector((state) => state.roleList);

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const userSchema = getUserSchema(user);

    useEffect(() => {
        loadInitialData(dispatch, resetStateData, fetchUser, id, [fetchRoles])
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        if ((status === 'saved' && !stateErrors.length) || !isValidId) {
            dispatch(setSuccessMessage(`Успешно запазен потребител ${user.name}`))
            dispatch(resetStateData());
            navigate('/settings/users');
        }
    }, [navigate, stateErrors, status, dispatch, isValidId]);

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await processSubmit(setErrors, userSchema, saveUser, dispatch, user);
    };

    const showForm = () => {
        return !loading && !rolesLoading;
    }

    const getTitle = () => {
        return idIsInteger ? `Потребител № ${id}` : 'Нов потребител';
    }

    return (
        <>
        <SingleEditLayout
            title={getTitle()}
            showForm={showForm()}
            onSubmit={handleSubmit}
            stateErrors={stateErrors}
        >
            <UserForm
                user={user}
                onAttributeChange={handleAttributeChange}
                errors={errors}
                roles={roles}
            />
        </SingleEditLayout>
        </>
    );
};

export default UserEditPage;
