import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import {processIdInformation} from "../../../utils/functions.js";
import SingleFilesLayout from "../../../components/Layouts/SingleFilesLayout.js";
import CarNav from "./components/CarNav.js";
import {fetchFiles, resetStateData as resetFilesStateData} from '../../../slices/fileListSlice.js';

const CarFilesPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: car, loading, errors: stateErrors} = useSelector((state) => state.car);
    const {data: files, loading: filesLoading} = useSelector((state) => state.fileList);

    const [title, setTitle] = useState('');

    useEffect(() => {
        return () => {
            dispatch(resetFilesStateData());
        }
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        setTitle( idIsInteger ? `Кола № ${id} - ${car.registrationNumber}` : 'Нова Кола');
    }, [car.id]);

    const showPage = () => {
        return !loading && !filesLoading;
    }

    return (
        <SingleFilesLayout
            title={title}
            showPage={showPage()}
            stateErrors={stateErrors}
            navComponent={CarNav}
            navProps={{id: id, activeKey: 'files'}}
            item = {car}
            itemsPath={'cars'}
            files={files}
            onFilesUpdate={() => dispatch(fetchFiles({id: id, filesItemPath: 'cars'}))}
        />
    );
};



export default CarFilesPage;
