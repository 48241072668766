import {Card } from "react-bootstrap";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import StandardTable from "../../../../components/StandardTable.js";
import TableLinkIconButton from "../../../../components/TableLinkIconButton.js";
import {PencilSquare} from "react-bootstrap-icons";

const RolesTable = (
    {
        data,
        showTable
    }) => {
    const headers = ['№', 'Име', ''];

    return (
        <Card>
            {showTable ? (
                <StandardTable headers={headers}>
                    {data.map((role) => (
                        <tr key={role.id}>
                            <td>{role.id}</td>
                            <td>{role.name}</td>
                            <td>
                                <TableLinkIconButton to={`/settings/roles/${role.id}/edit`}>
                                    <PencilSquare />
                                </TableLinkIconButton>
                            </td>
                        </tr>
                    ))}
                </StandardTable>
            ) : (
                <LoadingComponent/>
            )}
        </Card>
    )
}

export default RolesTable;