import apiClient from "../../../../axios.js";
import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import OrderCommentModal from "./OrderCommentModal.js";
import OrderCommentsTable from "./OrderCommentsTable.js";

const OrderCommentsComponent = (
    {
        order
    }) => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const endPoint = `/orders/${order.id}/comments`;

    const fetchData = async () => {
        const commentsResponse = await apiClient.get(endPoint);
        setData(commentsResponse.data.data);
    }

    useEffect(() => {
        if (order.id) {
            fetchData();
        }
    }, []);

    const createComment = async (comment) => {
        await apiClient.post(endPoint, {comment: comment});
        await fetchData();
        setShowModal(false);
    }

    return (
        <>
            <div className={'clearfix mb-2'}>
                <h5 className={'float-start'}>Коментари</h5>
                <Button className={'create-btn float-end'} onClick={() => setShowModal(true)}>
                    Добави коментар
                </Button>
            </div>
            <div>
                <OrderCommentsTable data={data} />
            </div>
            {showModal &&
                <OrderCommentModal data={{
                    title: 'Нов коментар',
                    content: '',
                    action: '',
                    onSubmit: (comment) => {
                        createComment(comment);
                    }
                }} onClose={() => {
                    setShowModal(false);
                }
                } show={showModal}/>
            }
        </>
    )
};

export default OrderCommentsComponent;