import { Card } from "react-bootstrap";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import TableLinkIconButton from "../../../../components/TableLinkIconButton.js";
import {CardImage, CarFrontFill, CartCheckFill, EyeFill, PencilSquare} from "react-bootstrap-icons";
import {Link} from "react-router-dom";
import StandardTable from "../../../../components/StandardTable.js";

const ClientsTable = ({
    showTable,
    data
                      }) => {
    const headers = ['№', 'Име', 'Email', 'Телефон', 'ЕГН/ЕИК', 'ТИП', '']

    return (
        <Card>
            {showTable ? (
                <StandardTable headers={headers}>
                    {data.map((client) => (
                        <tr key={client.id}>
                            <td>{client.id}</td>
                            <td><Link to={`/clients/${client.id}`}>{client.name}</Link></td>
                            <td>{client.email}</td>
                            <td>{client.phoneNumber}</td>
                            <td>{client.identificationId}</td>
                            <td>{client.clientType.name}</td>
                            <td>
                                <TableLinkIconButton to={`/clients/${client.id}`}><EyeFill /></TableLinkIconButton>
                                <TableLinkIconButton to={`/clients/${client.id}/edit`}><PencilSquare /></TableLinkIconButton>
                                <TableLinkIconButton to={`/clients/${client.id}/cars`}><CarFrontFill /></TableLinkIconButton>
                                <TableLinkIconButton to={`/clients/${client.id}/orders`}><CartCheckFill /></TableLinkIconButton>
                                <TableLinkIconButton to={`/clients/${client.id}/files`} title={'Файлове'}><CardImage /></TableLinkIconButton>
                            </td>
                        </tr>
                    ))}
                </StandardTable>
            ) : (
                <LoadingComponent/>

            )}
        </Card>
    )
}

export default ClientsTable;