import {Button, Card} from "react-bootstrap";
import SearchQueryInputSubmitComponent from "../SearchQueryInputSubmitComponent.js";
import Col from "react-bootstrap/Col";
import PaginationComponent from "../PaginationComponent.js";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {useSearchParams} from "react-router-dom";

const ListPageContent = (
    {
        loading,
        FiltersComponent = null,
        TableComponent,
        pagination,
        data,
        resetStateData,
        fetchData
    }
) => {
    const defaultSearchState = {page: 1, search: '', filters: {}, sort: ['id', 'desc']};
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchState, setSearchState] = useState(defaultSearchState);
    const [filters, setFilters] = useState({});
    const [query, setQuery] = useState('');
    const initialLoad = useRef(true);

    useEffect(() => {
        const initialSearchState = {
            page: parseInt(searchParams.get('page')) || 1,
            search: searchParams.get('search') || '',
            filters: extractFiltersFromParams(searchParams),
            sort: extractSortFromParams(searchParams) || ['id', 'desc'],
        };

        setSearchState(initialSearchState);

        return () => {
            dispatch(resetStateData());
        }
    }, []);

    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }
        const params = new URLSearchParams();
        params.set('page', searchState.page.toString());

        if (searchState.search) {
            params.set('search', searchState.search);
        }

        if (searchState.filters) {
            Object.entries(searchState.filters).forEach(([key, value]) => {
                if (value) params.set(`filters[${key}]`, value.toString());
            });
        }

        if (searchState.sort?.length) {
            searchState.sort.forEach((value, idx) => {
                params.set(`sort[${idx}]`, value.toString());
            });
        }

        setSearchParams(params);
        dispatch(fetchData(searchState));
    }, [searchState]);


    const extractFiltersFromParams = (params) => {
        const filters = {};
        params.forEach((value, key) => {
            if (key.startsWith('filters[') && key.endsWith(']')) {
                const filterKey = key.slice(8, -1); // Extract key from filters[key]
                filters[filterKey] = value;
            }
        });
        return filters;
    };

    const extractSortFromParams = (params) => {
        const sort = [];
        let index = 0;

        while (params.get(`sort[${index}]`)) {
            sort.push(params.get(`sort[${index}]`));
            index++;
        }

        return sort.length ? sort : ['id', 'desc']; // Default if empty
    };

    const handlePageChange = (page) => {
        setSearchState((prevState) => {
            return {
                ...prevState,
                page: page
            }
        });
    };

    const handleQueryChangeAndSearch = (query) => {
        setQuery(query);
        triggerSearch({query: query});
    }

    const handleQueryChange = (query) => {
        setQuery(query);
    }

    const handleFilterChange = (filters) => {
        setFilters(filters);
    }

    const triggerSearch = (syncParams = {}) => {
        setSearchState((prevState) => {
            return {
                ...prevState,
                page: 1,
                filters: filters,
                search: syncParams.query ? syncParams.query : query
            }
        });
    };

    const clearFilters = () => {
        setSearchState(defaultSearchState);
    }


    return (
        <>
            {!loading &&
                <Card className={'mb-2'}>
                    <Card.Body>
                        <SearchQueryInputSubmitComponent
                            defaultQuery={searchState.search}
                            onSubmit={handleQueryChangeAndSearch}
                            onQueryChange={handleQueryChange}
                        />
                        {FiltersComponent &&
                            <FiltersComponent
                                filterData={searchState.filters}
                                onFilterChange={handleFilterChange}
                                showContent={!loading}
                            />
                        }
                        <Col md={12} className="d-flex justify-content-between mt-2 mb-2">
                            <Button onClick={() => triggerSearch()}>Филтрирай</Button>
                            <Button variant="danger" className="ms-auto" onClick={clearFilters}>
                                Изчисти филтри
                            </Button>
                        </Col>
                    </Card.Body>

                </Card>
            }
            <h6>Общо: {pagination.totalItems}</h6>
            <TableComponent data={data} showTable={!loading} onItemSave={() =>  dispatch(fetchData(searchState))}/>
            <PaginationComponent
                onPageChange={handlePageChange}
                pagination={pagination}
            />

        </>
    )
};

export default ListPageContent;