import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import SingleFilesLayout from "../../../components/Layouts/SingleFilesLayout.js";
import ClientNav from "./components/ClientNav.js";
import {fetchFiles, resetStateData as resetFilesStateData} from '../../../slices/fileListSlice.js';

const ClientFilesPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch();

    const {data: client, loading, errors: stateErrors} = useSelector((state) => state.client);
    const {data: files, loading: filesLoading} = useSelector((state) => state.fileList);

    const [title, setTitle] = useState('');

    useEffect(() => {
        return () => {
            dispatch(resetFilesStateData());
        }
    }, [id, dispatch]);


    useEffect(() => {
        setTitle(`Клиент № ${id} - ${client.name}` );
    }, [client.id]);

    const showPage = () => {
        return !loading && !filesLoading;
    }

    return (
        <SingleFilesLayout
            title={title}
            showPage={showPage()}
            stateErrors={stateErrors}
            navComponent={ClientNav}
            navProps={{id: id, activeKey: 'files'}}
            item = {client}
            itemsPath={'clients'}
            files={files}
            onFilesUpdate={() => dispatch(fetchFiles({id: id, filesItemPath: 'clients'}))}
        />
    );
};



export default ClientFilesPage;
