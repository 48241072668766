import {Routes, Route} from "react-router-dom";
import CarListPage from "../pages/Cars/List/CarListPage.js";
import CarEditPage from "../pages/Cars/Single/CarEditPage.js";
import CarViewPage from "../pages/Cars/Single/CarViewPage.js";
import {hasPermission} from "../utils/permissions.js";
import CarFilesPage from "../pages/Cars/Single/CarFilesPage.js";
import SingleCarWrapper from "../pages/Cars/SingleCarWrapper.js";
import CarOrdersPage from "../pages/Cars/Single/CarOrdersPage.js";
import CarLogsPage from "../pages/Cars/Single/CarLogsPage.js";

const CarRoutes = ({
    user
                   }) => (
    <Routes>
            <Route index element={<CarListPage />} />
        <Route
            path={':id'}
            element={<SingleCarWrapper />}
            >
            <Route index element={<CarViewPage />} />
            <Route path={'edit'} element={hasPermission(user, 'update-cars') ? <CarEditPage /> : <CarViewPage />}  />
            <Route path={'files'} element={hasPermission(user, 'update-cars') ? <CarFilesPage /> : <CarEditPage />}  />
            <Route path={'orders'} element={<CarOrdersPage />} />
            <Route path={'logs'} element={hasPermission(user, 'get-logs') ? <CarLogsPage /> : <CarEditPage />}  />
        </Route>
    </Routes>
);

export default CarRoutes;