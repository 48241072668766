import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchSuppliers, resetStateData} from '../../../slices/supplierListSlice.js';
import PaginationComponent from "../../../components/PaginationComponent.js";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import SuppliersTable from "./components/SuppliersTable.js";
import ListPageContent from "../../../components/Layouts/ListPageContent.js";

const SupplierListPage = () => {
    const {data: suppliers, loading, pagination} = useSelector((state) => state.supplierList);

    return (
        <div>
            <ListHeaderLayout
                title={'Доставчици'}
                createLink={'/settings/suppliers/create/edit'}
            />
            <ListPageContent
                resetStateData={resetStateData}
                fetchData={fetchSuppliers}
                data={suppliers}
                pagination={pagination}
                loading={loading}
                TableComponent={SuppliersTable}
            />
        </div>
    );
};

export default SupplierListPage;
