import React from 'react';
import {  useSelector } from 'react-redux';
import {fetchUsers, resetStateData} from "../../../slices/userListSlice.js";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import UsersTable from "./components/UsersTable.js";
import ListPageContent from "../../../components/Layouts/ListPageContent.js";

const UserListPage = () => {
    const {data: users, loading, pagination} = useSelector((state) => state.userList);

    return (
        <div>
            <ListHeaderLayout
                title={'Потребители'}
                createLink={'/settings/users/create/edit'}
            />
            <ListPageContent
                resetStateData={resetStateData}
                fetchData={fetchUsers}
                loading={loading}
                TableComponent={UsersTable}
                pagination={pagination}
                data={users}
            />
        </div>
    );
};

export default UserListPage;
