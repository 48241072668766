import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from "react-router-dom";
import {changeAttribute, saveClient, setStatus} from "../../../slices/clientSlice.js";
import { processIdInformation, processSubmit} from "../../../utils/functions.js";
import ClientForm from "./components/ClientForm.js";
import {fetchClientTypes} from "../../../slices/clientTypeListSlice.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import ClientNav from "./components/ClientNav.js";
import {getClientSchema} from "../../../utils/schemas.js";
import {setSuccessMessage} from "../../../slices/informationMessagesSlice.js";

const ClientEditPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: client, loading, errors: stateErrors, status} = useSelector((state) => state.client);
    const {data: clientTypes, loading: clientTypesLoading} = useSelector((state) => state.clientTypeList)

    const navigate = useNavigate();

    const [errors, setErrors] = useState({});
    const [title, setTitle] = useState('');

    const clientSchema = getClientSchema();

    useEffect(() => {
        if (!clientTypes.length) {
            dispatch(fetchClientTypes());
        }
    }, []);

    useEffect(() => {
        setTitle(idIsInteger ? `Клиент № ${id} - ${client.name}` : 'Нов клиент');
    }, [client.id]);

    useEffect(() => {
        if (status === 'saved') {
            dispatch(setSuccessMessage(`Успешно запазен клиент - ${client.name}`));
            dispatch(setStatus('idle'));
            navigate(`/clients/${client.id}`);
        }
    }, [status]);

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await processSubmit(setErrors, clientSchema, saveClient, dispatch, client);
    };

    const showForm = () => {
        return !loading && !clientTypesLoading;
    }

    return (
        <SingleEditLayout
            title={title}
            showForm={showForm()}
            onSubmit={handleSubmit}
            stateErrors={stateErrors}
            navComponent={ClientNav}
            navProps={{id: id, activeKey: 'edit'}}
        >
            <ClientForm
                onAttributeChange={handleAttributeChange}
                errors={errors}
                client={client}
                clientTypes={clientTypes}
            />
        </SingleEditLayout>
    );
};

export default ClientEditPage;
