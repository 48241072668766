import { Card } from "react-bootstrap";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import StandardTable from "../../../../components/StandardTable.js";
import TableLinkIconButton from "../../../../components/TableLinkIconButton.js";
import {PencilSquare} from "react-bootstrap-icons";

const UsersTable = (
    {
        data,
        showTable
                   }) => {
    const headers = ['№', 'Име', 'Email', ''];

    return (
        <Card>
            {showTable ? (
                <StandardTable headers={headers}>
                    {data.map((user) => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>
                                <TableLinkIconButton to={`/settings/users/${user.id}/edit`}>
                                    <PencilSquare />
                                </TableLinkIconButton>
                            </td>
                        </tr>
                    ))}
                </StandardTable>
            ) : (
                <LoadingComponent/>
            )}
        </Card>
    )
}

export default UsersTable;