import React from 'react';
import {  useSelector } from 'react-redux';
import {fetchRoles, resetStateData} from "../../../slices/roleListSlice.js";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import ListPageContent from "../../../components/Layouts/ListPageContent.js";
import RolesTable from "./components/RolesTable.js";

const RoleListPage = () => {
    const {data: roles, loading, pagination} = useSelector((state) => state.roleList);

    return (
        <div>
            <ListHeaderLayout
                title={'Роли'}
            />
            <ListPageContent
                resetStateData={resetStateData}
                fetchData={fetchRoles}
                loading={loading}
                TableComponent={RolesTable}
                pagination={pagination}
                data={roles}
            />
            </div>
    );
};

export default RoleListPage;
