import React from 'react';
import { useSelector } from 'react-redux';
import {fetchCarModels, resetStateData} from '../../../slices/carModelListSlice.js';
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import CarModelsTable from "./components/CarModelsTable.js";
import ListPageContent from "../../../components/Layouts/ListPageContent.js";

const CarModelListPage = () => {
    const {data: carModels, loading, pagination} = useSelector((state) => state.carModelList);

    return (
        <div>
            <ListHeaderLayout
                title={'Модели коли'}
                createLink={'/settings/car-models/create/edit'}
                />
            <ListPageContent
                resetStateData={resetStateData}
                fetchData={fetchCarModels}
                loading={loading}
                TableComponent={CarModelsTable}
                pagination={pagination}
                data={carModels}
            />
        </div>
    );
};

export default CarModelListPage;
