import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import parse from "html-react-parser";
import {numberFormatSpaceThousands} from "../../../../utils/functions.js";
import apiClient from "../../../../axios.js";
import OrderCommentsTable from "../../Single/components/OrderCommentsTable.js";
import LoadingComponent from "../../../../components/LoadingComponent.js";

const OrderDetailsTableModal = (
    {
        show,
        onClose,
        order
    }
) => {
    const [comments, setComments] = useState([]);
    const [commentsFetched, setCommentsFetched] = useState(false);
    const endPoint = `/orders/${order.id}/comments?perPage=10`;

    const fetchComments = async () => {
        const commentsResponse = await apiClient.get(endPoint);
        setComments(commentsResponse.data.data);
        setCommentsFetched(true);
    }

    useEffect(() => {
        if (order.id) {
            fetchComments();
        }
    }, [order.id]);

    return (
        <Modal show={show} onHide={onClose} animation={false} size={'xl'}>
            <Modal.Header closeButton>
                <Modal.Title>Поръчка № {order.id} - {order.client.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'mb-3'}>
                    {order.insurer ? (
                        <>
                            <h5><b>Застраховател:</b> {order.insurer?.name}</h5>
                            <h5><b>Номер щета:</b> {order.insurerClaimId ? order.insurerClaimId : 'частен ремонт'}</h5>
                        </>
                    ) : (
                        <h5>Частен ремонт</h5>
                    )}
                </div>
                <div className={'mb-3'}>
                    <h5><b>Клиент фактура:</b> {order.invoiceClient?.name}</h5>
                    <h5><b>Номер фактура:</b> {order.invoiceNumber}</h5>
                </div>
                {order.mileage &&
                    <div className={'mb-3'}>
                        <h5 ><b>Пробег:</b> {numberFormatSpaceThousands(order.mileage)} км</h5>
                    </div>
                }
                {order.additionalInformation &&
                    <div className={'mb-3'}>
                        <h5><b>Бележка:</b> {parse(order.additionalInformation)}</h5>
                    </div>
                }

                <div>
                    <b>Коментари (последни 10):</b>
                    {commentsFetched ?
                        <OrderCommentsTable data={comments}/>
                        :
                        <LoadingComponent/>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
};

export default OrderDetailsTableModal;