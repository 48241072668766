import React, { useState } from 'react';
import apiClient from "../axios";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchUser } from '../slices/authSlice';
import { Button, Container} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ErrorAlertComponent from "../components/ErrorAlertComponent.js";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        await apiClient.get(process.env.REACT_APP_API_HOST + '/sanctum/csrf-cookie');

        try {
            let response = await apiClient.post('/login', {email, password})
            if (response.status === 200) {
                await dispatch(fetchUser({}));
                navigate('/home');
                localStorage.setItem('loginError', '');
            }
        } catch (error) {
            localStorage.setItem('loginError', 'Грешен потребител или парола');
            setButtonDisabled(false);
        }
    };

    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={4} className="mt-5">
                    <h2>Вход</h2>
                    <ErrorAlertComponent error={localStorage.getItem('loginError')} />


                    <Form onSubmit={handleSubmit} autoComplete="none">
                        <Form.Label>E-Mail</Form.Label>
                        <Form.Control
                            name={'email'}
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Form.Label>Парола</Form.Label>
                        <Form.Control
                            name={'password'}
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button type="submit" variant={'success'} className={'mt-2'} disabled={buttonDisabled}>Влез</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
