import { Card } from "react-bootstrap";
import {formatDate} from "../../../../utils/dateFunctions.js";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import TableLinkIconButton from "../../../../components/TableLinkIconButton.js";
import {CardImage, CartCheckFill, EyeFill, PencilSquare} from "react-bootstrap-icons";
import {Link} from "react-router-dom";
import StandardTable from "../../../../components/StandardTable.js";

const CarsTable = ({
    data,
    showTable
                   }) => {
    const headers = ['№', 'Клиент', 'Рег. номер', 'Марка/Модел', 'ВИН', 'Първа регистрация', ''];

    return (
        <Card>
            {showTable ? (
                <StandardTable headers={headers}>
                    {data.map((car) => (
                        <tr key={car.id}>
                            <td>{car.id}</td>
                            <td><Link to={`/clients/${car.client.id}`} target={'_blank'}>{car.client.name}</Link></td>
                            <td><Link to={`/cars/${car.id}`} >{car.registrationNumber}</Link></td>
                            <td>{car.carModel.modelManufacturerName}</td>
                            <td>{car.vinNumber}</td>
                            <td>{formatDate(car.firstRegisteredAt)}</td>
                            <td>
                                <TableLinkIconButton to={`/cars/${car.id}`} title={'Преглед'}><EyeFill /></TableLinkIconButton>
                                <TableLinkIconButton to={`/cars/${car.id}/edit`} title={'Редактирай'}><PencilSquare /></TableLinkIconButton>
                                <TableLinkIconButton to={`/cars/${car.id}/orders`} title={'Поръчки'}><CartCheckFill /></TableLinkIconButton>
                                <TableLinkIconButton to={`/cars/${car.id}/files`} title={'Файлове'}><CardImage /></TableLinkIconButton>
                            </td>
                        </tr>
                    ))}
                </StandardTable>
            ) : (
                <LoadingComponent/>
            )}
        </Card>
    )
}

export default CarsTable;