import {Button, Card } from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import StandardTable from "../../../../components/StandardTable.js";
import TableLinkIconButton from "../../../../components/TableLinkIconButton.js";
import {PencilSquare} from "react-bootstrap-icons";

const SuppliersTable = ({
    data,
    showTable
                               }) => {
    const headers = ['№', 'Име', ''];

    return (
        <Card>
            {showTable ? (
                <StandardTable headers={headers}>
                    {data.map((supplier) => (
                        <tr key={supplier.id}>
                            <td className={'col-1'}>{supplier.id}</td>
                            <td className={'col-2'}>
                                <Link to={`/settings/suppliers/${supplier.id}`}>{supplier.name}</Link>
                            </td>
                            <td className={'col-2 d-none d-md-table-cell'}>
                                <TableLinkIconButton to={`/settings/suppliers/${supplier.id}/edit`}>
                                    <PencilSquare />
                                </TableLinkIconButton>

                            </td>
                        </tr>
                    ))}
                </StandardTable>
            ) : (
                <LoadingComponent/>
            )}
        </Card>
    )
}

export default SuppliersTable;