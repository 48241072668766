import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import StartDateInput from "../../Single/components/fields/StartDateInput.js";
import {useDispatch, useSelector} from "react-redux";
import {
    saveOrder,
    changeAttribute, resetStateData, addOrderComment, setStatus, fetchOrder
} from "../../../../slices/orderSlice.js";
import EndDateInput from "../../Single/components/fields/EndDateInput.js";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import {fetchClientCars, resetStateData as resetCarListStateData} from "../../../../slices/carListSlice.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonLink from "../../../../components/Elements/ButtonLink.js";
import MileageInput from "../../Single/components/fields/MileageInput.js";
import PaymentTypeSelect from "../../Single/components/fields/PaymentTypeSelect.js";
import InvoiceData from "../../Single/components/fields/InvoiceData.js";
import SearchAutoCompleteComponent from "../../../../components/SearchAutoCompleteComponent.js";
import {fetchClients, resetStateData as clientListResetStateData} from "../../../../slices/clientListSlice.js";
import {v4 as uuidv4} from "uuid";
import FormInput from "../../../../components/Elements/FormInput.js";
import {isEmptyObject, processSubmit} from "../../../../utils/functions.js";
import {getOrderSchema} from "../../../../utils/schemas.js";
import {setSuccessMessage} from "../../../../slices/informationMessagesSlice.js";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import {Link} from "react-router-dom";
import ErrorAlertComponent from "../../../../components/ErrorAlertComponent.js";

const OrderStatusTableModal = (
    {
        show,
        onClose,
        editOrder,
        orderStatus,
        onOrderSave
    }
) => {
    const dispatch = useDispatch();
    const {data: cars, loading: carsLoading} = useSelector((state) => state.carList);

    const {data: order, status, loading: orderLoading, errors: stateErrors} = useSelector((state) => state.order);
    const [errors, setErrors] = useState({});
    const [comment, setComment] = useState('');

    useEffect(() => {
        dispatch(fetchOrder({id: editOrder.id}));

        return () => {
            dispatch(resetStateData());
            dispatch(resetCarListStateData());
        }
    }, []);

    useEffect(() => {
        if (order.id) {
            handleAttributeChange('orderStatus', orderStatus);

            if (orderStatus.settings.needsCar) {
                dispatch(fetchClientCars({id: order.client.id}));
            }
        }
    }, [order.id]);

    useEffect(() => {
        if (status === 'saved') {
            dispatch(setSuccessMessage(`Успешно запазена поръчка - ${order.id}`));
            dispatch(setStatus('idle'));
            onOrderSave();
            onClose();
        }
    }, [status]);

    useEffect(() => {
        if (comment && order.newOrderComments.length === 1) {
            processSubmit(setErrors, getOrderSchema(order), saveOrder, dispatch, order);
        }
    }, [order.newOrderComments]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (orderStatus.settings.needsComment) {
            if (!comment.length) {
                setErrors({
                    orderCommentContent: 'Добави коментар!'
                })
            } else if (comment) {
                dispatch(addOrderComment({
                    id: uuidv4(),
                    action: 'order_status_change',
                    comment: comment,
                    actionDetail: orderStatus.name,
                }));
            }
        } else {
            await processSubmit(setErrors, getOrderSchema(order), saveOrder, dispatch, order);
        }
    }

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    return (
        <Modal show={show} onHide={onClose} animation={false} size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title>Смяна на статус - {orderStatus.name} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {order.id ? (
                    <>
                        <ErrorAlertComponent error={stateErrors}/>

                        <h5 className={'mb-3'}>Клиент: {order.client.name}</h5>
                        <Row>
                            {orderStatus.settings.needsStartDate &&
                                <StartDateInput startDate={order.startDate} errors={errors}
                                                onAttributeChange={handleAttributeChange}/>
                            }
                            {orderStatus.settings.needsEndDate &&
                                <EndDateInput endDate={order.endDate} errors={errors}
                                              onAttributeChange={handleAttributeChange}/>
                            }
                            {orderStatus.settings.needsCar &&
                                <>
                                    <Col md={6}>
                                        <SelectInput
                                            label={'Кола'}
                                            name={'car'}
                                            value={order.car?.id}
                                            onChange={handleAttributeChange}
                                            selectOptions={cars}
                                            disabled={carsLoading}
                                        />
                                    </Col>
                                    <Col md={5}>
                                        <Button onClick={() => dispatch(fetchClientCars({id: order.client?.id}))} className={'m-2'}>
                                            Презареди коли
                                        </Button>
                                        <ButtonLink isCreate={true} target={"_blank"} to={'/cars/create/edit'}>Добави кола</ButtonLink>
                                    </Col>
                                </>
                            }
                            {orderStatus.settings.needsMileage &&
                                <Col md={5}>
                                    <MileageInput order={order} errors={errors}
                                                  onAttributeChange={handleAttributeChange}/>
                                </Col>
                            }
                            {orderStatus.settings.needsInvoiceData &&
                                <Row>
                                    <Col md={12}>
                                        <SearchAutoCompleteComponent
                                            onItemChange={handleAttributeChange}
                                            item={order.invoiceClient}
                                            name={'invoiceClient'}
                                            label={'Клиент фактура'}
                                            fetchItems={fetchClients}
                                            reducer={'clientList'}
                                            errors={errors}
                                            itemLabel={'searchLabel'}
                                            inline={true}
                                            resetStateData={clientListResetStateData}
                                        />
                                        <InvoiceData order={order} onAttributeChange={handleAttributeChange}
                                                     errors={errors}/>
                                    </Col>
                                </Row>
                            }
                            {orderStatus.settings.needsPaymentType &&
                                <Col md={5}>
                                    <PaymentTypeSelect onAttributeChange={handleAttributeChange}
                                                        paymentType={order.paymentType} errors={errors}/>
                                </Col>
                            }
                            {orderStatus.settings.needsComment &&
                                <FormInput
                                    name={'orderCommentContent'}
                                    onChange={(name, value) => {
                                        setComment(value)
                                    }}
                                    value={comment}
                                    as={'textarea'}
                                    label={'Добави коментар'}
                                    errors={errors}
                                />
                            }
                            {(!isEmptyObject(errors) || stateErrors.length > 0) &&
                                <p className={'small mt-3'}>
                                    <span className={'text-danger'}><b>Има грешка. Ако не може да бъде поправена</b> - </span>
                                    <Link to={`/orders/${order.id}/edit`} target={"_blank"}>провери поръчка</Link>.
                                </p>
                            }
                        </Row>
                    </>
                ) : (
                    <LoadingComponent className={'position-relative'}/>
                )}
            </Modal.Body>
            <Modal.Footer>
                {(order.id && !orderLoading) &&
                    <>
                        <Button variant="danger" onClick={onClose}>Откажи</Button>
                        <Button variant="success" onClick={handleSubmit}>Запази</Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default OrderStatusTableModal;