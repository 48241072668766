import Modal from "react-bootstrap/Modal";
import FormInput from "../../../../components/Elements/FormInput.js";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";

const OrderCommentModal = ({
    show,
    data,
    onClose
}) => {
    const [comment, setComment] = useState('');
    const [errors, setErrors] = useState({});
    const [submitDisabled, setSubmmitDisabled] = useState(false);
    useEffect(() => {
        setComment(data['content'])
    }, []);

    const handleSubmit = () => {
        setSubmmitDisabled(true);
        if (!comment.length) {
            setErrors({
                orderCommentContent: 'Добави коментар!'
            })
            setSubmmitDisabled(false);
        } else {
            setErrors({});
            data.onSubmit(comment);
        }
    }

    return (
        <Modal show={show} onHide={onClose} animation={false} size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title>{data.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormInput
                    name={'orderCommentContent'}
                    onChange={(name, value) => {
                        setComment(value)
                    }}
                    value={comment}
                    as={'textarea'}
                    label={'Добави коментар'}
                    errors={errors}
                    helpText={data.helpText ? data.helpText : ''}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onClose}>Откажи</Button>
                <Button variant="success" onClick={handleSubmit} disabled={submitDisabled}>Запази</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default OrderCommentModal;